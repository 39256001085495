import React, { useState, useCallback, useMemo } from 'react';
import { Formik, useField } from 'formik';
import { Grid, Typography, Box, Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText, SliderValueLabelUnstyled } from '@mui/material';
import { postCommand, useRefresh } from '../../data/fetch';
import { COMMAND } from '../../../common';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../../components/input';
import DeleteIcon from '@mui/icons-material/Delete';
import AddressField from '../../parcel/AddressField';
import AssetField from '../../parcel/AssetField';

const types = ['Kiszállítás', 'Minta darab', 'Alapanyag elhozatal', 'Visszáru', 'Beszerzés', 'Alvállalkozói kiszállítás', 'Alvállalkozótól vissza', 'Áttárolás', 'Kellék kiszállítás'];

export default function DeliveryDialog(props) {
  let { rec, op } = props.open;
  let refresh = useRefresh();
  console.log('DeliveryDialog', rec);
  const submit = async (values) => {
    values.address = `${values.country || ''} ${values.zip || ''} ${values.city || ''}, ${values.street || ''}${values.street2 ? ' ' + values.street2 : ''}`;
    if (rec.address !== values.address || rec.partner !== values.partner || rec.smemo !== values.smemo || rec.print !== values.print ||
      rec.type !== values.type || rec.company !== values.company ||
      (rec.contact && (rec.contact.name !== values.contact.name || rec.contact.tel !== values.contact.tel))
    ) {
      await postCommand('delivery', COMMAND.UPDATE_ADDRESS, { delivery: rec.id, updates: values });
    }
    if (rec.asset !== values.asset || rec.date !== values.date) {
      await postCommand('delivery', COMMAND.SCHEDULE_DELIVERY, { delivery: rec.id, updates: values });
    }

    refresh();
    props.close();
  }

  const validate = useCallback((rec) => {
    console.log("validate", rec);
    let ret = {};
    if (!rec) rec = {};
    if (!rec.date) ret.date = 'A dátumot meg kell adni';
    if (!rec.type) ret.type = 'Az irányt meg kell adni';

    if (!rec.partner) {
      ret.partner = 'Melyik partnerhez kapcsolódik?';
    }
    return ret;
  }, []);

  const del = async () => {
    await postCommand('delivery', COMMAND.DELETE_DELIVERY, rec.id);
    refresh();
    props.close();
  }

  let { partner, asset, date, country, zip, city, street, index, smemo, contact, company, print, type } = rec.parcel[0] || {};
  return <Formik initialValues={{ partner, asset, date, country, zip, city, street, index, smemo, contact, company, print, type }} onSubmit={submit} validate={validate} {...props}>{({ handleSubmit, isSubmitting, errors }) => (
    <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }} autocomplete="off">
      <DialogTitle>Kártya szerkesztése</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} >
            <ToggleField name="print" options={[
              { value: 'Szállítólevél', label: 'Szállítólevél' },
              { value: 'Átadási', label: 'Átadási' },
              { value: 'Inkognitó', label: 'Inkognitó' }
            ]} />
          </Grid>
          <Grid item xs={12} md={6} >
            <ToggleField name="company" options={[
              { value: 'MACMA', label: 'MACMA' },
              { value: 'Easy Gifts', label: 'Easy Gifts' },
              { value: 'Spark', label: 'Spark' },
            ]} />
          </Grid>
          <Grid item xs={12} md={12} >
            <EnumField name="type" label="Szállítási irány" options={types.map(t => ({ value: t, label: t }))} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField name="partner" inputProps={{ style: { fontWeight: 'bold' } }} label="Partner" />
            <DateField name="date" label="Szállítás napja" />
            <AssetField />
          </Grid>
          <Grid item xs={12} md={4}>
            <AddressField />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField name="contact.name" label="Kontakt" />
            <TextField name="contact.tel" label="Telefon" />
            <TextField name="smemo" multiline label="Megjegyzés" />
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        <IconButton onClick={del}><DeleteIcon /></IconButton><div style={{ flex: '1 0 0' }} />
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Mentés</Button>
      </DialogActions>
    </form >
  )}
  </Formik>
}