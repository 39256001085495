import { useCallback, useMemo } from 'react';
import { IconButton, Button, Box, Paper, Grid, Typography, Link, Card, CardHeader, CardContent, Table, TableRow, TableCell, TableContainer, TableBody, Tooltip } from "@mui/material";
import { useDialog } from '../components/Dialog'
import { Header } from '../components/Header';
import { Panel, useTab, PageTabs, PanelField } from '../components/Panel';
import { dateToMoment, format } from '../../utils';
import DataDisplay from '../components/DataDisplay'
import { Block, Cancel, Delete, DoneAll, Edit, LocalShipping, Map, MoreVert, Refresh, ShoppingCart } from '@mui/icons-material';
import { postCommand, useJSON, useRefresh } from '../data/fetch';
import StatusIcon from '../parcel/StatusIcon';
import { COMMAND } from '../../common';


export let bgcolor = {
  "MACMA": "#EE6600",
  "Easy Gifts": "#6B508B",
  "Spark": "#ADD342"
}

export default function DeliveryPanel({ id, sidebar }) {
  const refresh = useRefresh();
  let { setDialog } = useDialog();
  const delivery = useJSON({ url: '/delivery/' + sidebar.open.driver_delivery, setNull: true });
  if (!delivery) return <Panel header={<Header color='#ffffff' bgcolor='#ccc' caption='' title='Adatok betöltése...' subtitel='' icon='' sidebar={sidebar} />} content={<Box sx={{ bgcolor: 'background.default', p: 2, overflow: 'auto', minHeight: '100%' }}></Box>} />

  const loaded = async () => {
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: id, status: 'LOADED' });
    refresh();
  }

  const kiszall = async () => {
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: id, status: 'DELIVERED' });
    refresh();
  }

  const nemsikerult = async () => {
    setDialog({ id: 'failed', rec: { id } });
  }


  let d = delivery;
  let orders = delivery.parcel.reduce((acc, p) => {
    let id = p.order || p.type;
    if (acc[id]) {
      acc[id].item.push(p);
    } else {
      acc[id] = { order: p.order, type: p.type, id, item: [p] }
    }
    return acc;
  }, []);

  let op = [];
  if (d.statusenum === 1) {
    op.push(<Tooltip key="loaded" title="Átvéve"><IconButton onClick={loaded}><LocalShipping /></IconButton></Tooltip>);
  }

  if (d.statusenum === 2) {
    op.push(<Tooltip key="delivered" title="Kiszállítva"><IconButton onClick={kiszall}><DoneAll /></IconButton></Tooltip>);
  }

  return <Panel header={
    <Header color='#ffffff' bgcolor={bgcolor[d.company]}
      title={`${d.partner}`}
      subtitle={<>{d.address} <Link component={IconButton} href={`https://google.com/maps/?q=${encodeURIComponent(d.address)}`} target="maps" ><Map /></Link></>}
      sidebar={sidebar}
      icon={<StatusIcon d={d} />}
      caption={d.status || 'Nincs összekészítve'}
      menu={<div>
        {op}
      </div>}

    />
  }
    content={<Box sx={{ bgcolor: 'background.default', p: 2, overflow: 'auto', minHeight: '100%' }}>
      <Grid container>
        <DataDisplay label="Szállítás" data={d.date} xs={8} format={format.date} />
        <DataDisplay label="Nyomtatvány" data={d.print} xs={4} />
        <DataDisplay label="Karton" data={d.cartons} xs={4} />
        <DataDisplay label="Paletta" data={d.palettes} xs={4} />
        <DataDisplay label="Súly" data={d.weight} xs={4} />
        <DataDisplay label="Sikertelen" data={d.failed} xs={4} format={format.fromNow} />
        <DataDisplay label="Ok" data={d.failreason} xs={4} />
        <DataDisplay label="Megjegyzés" data={d.failmemo} xs={4} />
      </Grid>

      {Object.values(orders).map(o => <Order key={o.order} o={o} setDialog={setDialog} />)}

      <div style={{ margin: 8, display: 'flex', justifyContent: 'space-around' }}>
        {d.statusenum === 1 && <Button variant="contained" color="success" startIcon={<LocalShipping />} onClick={loaded}>Átvéve</Button>}
        {d.statusenum === 2 && <Button variant="contained" color="error" startIcon={<Block />} onClick={nemsikerult}>Sikertelen</Button>}
        {d.statusenum === 2 && <Button variant="contained" color="success" startIcon={<DoneAll />} onClick={kiszall}>Kézbesítve</Button>}
      </div>
    </Box>}
  />
}


function Order({ o, setDialog }) {
  const { sellto, contact, ext, memo, memo2, smemo, salesperson } = o.item[0];
  //  console.log("Order", o);
  return <Card style={{ marginBottom: 8 }}>
    <div style={{ padding: 8 }}>
      <div style={{ display: 'flex' }}>
        <Typography variant="subtitle2" style={{ flexGrow: 1, padding: 4, textAlign: 'center' }}>{o.order || o.type || 'Raktárosi megbízás'}
        </Typography>
        <div>
        </div>
      </div>
      <Grid container>
        {sellto && <DataDisplay xs={6} label='Partner' data={`${sellto.name} (${sellto.no})`} />}
        {sellto && <DataDisplay xs={6} label='Partner kontakt' data={`${sellto.contact.name || ''} (${sellto.contact.tel || ''})`} />}
        {salesperson && <DataDisplay xs={6} label='Üzletkötő' data={`${salesperson.name || ''} (${salesperson.tel || ''})`} />}
        {contact && <DataDisplay xs={6} label='Cím kontakt' data={`${contact.name} (${contact.tel || ''})`} />}
        <DataDisplay xs={12} label='Külső bizonylatszám' data={ext} />
        <DataDisplay xs={12} label='Megjegyzés' data={memo} />
        <DataDisplay xs={6} label='Webshop Megjegyzés' data={memo2} />
        <DataDisplay xs={6} label='Sofőr megjegyzés' data={smemo} />
      </Grid>
    </div>
    {o.item && o.item.sort((a, b) => a.salesline - b.salesline).map((i, idx) => <Item key={i.salesline || idx} i={i} setDialog={setDialog} />)}
  </Card>
}

function ParcelLine({ l }) {
  return <Typography variant="body2" component="div" style={{ padding: 4, fontSize: 12, borderTop: '1px solid #ccc' }}>
    {l.quantity || '? '}db {l.sku} {l.name}
  </Typography>
}

function Item({ i, setDialog }) {
  let lines = null;
  if (i.lines) {
    lines = i.lines.map((l, idx) => <ParcelLine key={idx} l={l} />);
    return lines;
  }

  return <Typography variant="body2" component="div" style={{ padding: 4, fontSize: 12, borderTop: '1px solid #ccc', display: 'flex' }} key={i.salesline}>
    <div style={{ maxWidth: 50, minWidth: 50, textAlign: 'right', paddingRight: 8, whiteSpace: 'nowrap', }}>{i.quantity} db</div>
    <div style={{ maxWidth: 90, minWidth: 90, whiteSpace: 'nowrap', }}>{i.item}</div>
    <div style={{ flexGrow: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{i.name}</div>
  </Typography>
}