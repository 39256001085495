import "./styles.css";
import { useState } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { SearchInput } from "../components/Search";
import ArchiveWindow from "./ArchiveWindow";

const drawerWidth = 240;

const Archive = ({ url }) => {
  const [term, setTerm] = useState('');
  return <Box sx={{ display: 'flex' }}>
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
      <Toolbar variant="dense">
        <Typography variant="h6" noWrap component="div">
          Kiszállítások
        </Typography>
        <SearchInput setSearch={setTerm} value={term} />
      </Toolbar>
    </AppBar>
    <ArchiveWindow term={term}/>
  </Box>
}


export default Archive;