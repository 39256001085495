import React, { useState, useCallback, useMemo } from 'react';
import { Formik, useField } from 'formik';
import { Grid, Typography, Box, Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { fetchJSON } from '../../data/fetch';
import { COMMAND } from '../../../common';
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from 'lodash';
import { useDialog } from '../../components/Dialog';

export default function TemplateDialog(props) {
  const { setDialog } = useDialog();
  const [templates, setTemplates] = useState([]);

  const select = async (values) => {
    console.log("Selected", values);
    const { type, partner, company, city, zip, country, street, contact } = values;
    setDialog({
      id: 'parcel', rec: { type, partner, company, city, zip, country, street, contact }, op: 'insert'
    });
  }

  const del = async (id) => {
    await fetchJSON('POST', '/delivery/hidetemplate/' + id);
    setTemplates(templates.filter(t => t.id !== id));
  }

  const search = debounce(async (ev) => {
    const { value } = ev.target;
    if (value.length > 2) {
      let t = await fetchJSON('POST', '/delivery/templates', { term: value });
      console.log("templates", t);
      setTemplates(t);
    } else {
      setTemplates([]);
    }
  }, 1000)

  const height = 190;

  return <>
    <DialogTitle>Új szállítás</DialogTitle>
    <Box sx={{ p: 2 }}>
      <MUITextField fullWidth variant="standard" label="Sablon" autoFocus onChange={search} />
      <div style={{ height, overflow: 'auto' }}>
        <Table size="small" >
          <TableBody>
            {templates.map(t => <TableRow hover key={t.id} onClick={() => select(t)}>
              <TableCell>{t.partner}</TableCell>
              <TableCell>{t.type}</TableCell>
              <TableCell>{t.city} {t.street}</TableCell>
              <TableCell><IconButton onClick={(ev) => {
                ev.stopPropagation();
                del(t.id)
              }}><DeleteIcon /></IconButton></TableCell>
            </TableRow>)
            }
          </TableBody>
        </Table>
      </div>
    </Box>
    <DialogActions>
      <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
      <Button variant="contained" type="submit" onClick={()=>setDialog({id: 'parcel', rec: {}, op: 'insert'})}>Tovább</Button>
    </DialogActions>
  </>
}