import "./styles.css";
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import moment from "moment";
import { dateFromMoment, dateToMoment, format, now, nowdate } from "../../utils";
import { registerPanel, Sidebar, useSidebar } from '../components/Sidebar'
import { fetchJSON } from "../data/fetch";
import { Button, IconButton } from "@mui/material";
import { Formik, useField } from 'formik';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../components/input';
import DataTable from "../components/DataTable";
import StatusIcon from "../parcel/StatusIcon";

const drawerWidth = 240;

const ArchiveWindow = ({ term, interval, salesperson }) => {
  interval = Object.assign({ min: 7, default: 14, max: 30 }, interval || {});
  const baseurl = salesperson ? '/delivery/salesperson/archive' : '/delivery/archive';

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    from: dateFromMoment(moment().subtract(interval.min, 'd')),
    to: nowdate()
  });

  useEffect(() => {
    setData(false);
    fetchJSON('POST', baseurl, { ...filter, term }).then(setData);
  }, [term, setData])

  const submit = async (values) => {
    setData(false);
    let d = await fetchJSON('POST', baseurl, { ...values, term });
    setData(d);
    setFilter({ ...filter, ...values });
  }

  return <Box sx={{ display: 'flex', flexGrow: 100, bgcolor: 'background.paper' }}>
    <Box
      sx={{
        width: drawerWidth,
        flexShrink: 0,
      }}
    >
      <Toolbar variant="dense" />
      <Box sx={{ p: 2 }}>
        <ArchiveFilters value={filter} interval={interval} submit={submit} showCeg={!salesperson} />
      </Box>
    </Box>
    <Box style={{
      background: '#fff',
      flexGrow: 1,
      paddingTop: 50,
      height: '100vh'
    }}>
      <ArchiveTable data={data} showCeg={!salesperson} />
    </Box>
  </Box>
}

export default ArchiveWindow;

export function SalespersonArchive(opt) {
  return <ArchiveWindow salesperson={true} {...opt} />
}

function ArchiveFilters({ value, interval, submit, showCeg }) {

  const validate = values => {
    let err = {}
    let { from, to } = values;
    if (!from) err.from = "Kötelező megadni";
    if (!to) err.to = "Kötelező megadni";
    if (from && to) {
      let diff = dateToMoment(to).diff(dateToMoment(from), 'd');
      if (diff > interval.max) err.to = "Túl hosszú intervallum";
    }
    return err;
  }

  const calculateFrom = ({ newValue, form }) => {
    if (newValue) form.setFieldValue('to', dateFromMoment(dateToMoment(newValue).add(interval.default, 'd')));
  }


  return <Formik initialValues={value} validate={validate} onSubmit={submit}>{({ handleSubmit, isSubmitting, errors }) => (
    <form onSubmit={handleSubmit} autocomplete="off">
      <DateField name="from" label="Szállítás napja" calculate={calculateFrom} />
      <DateField name="to" label="" />
      <TextField name="partner" label="Partner" />
      <TextField name="order" label="Bizonylat" />
      <TextField name="item" label="Cikk" />
      {showCeg && <EnumField name="company" label='Cég' options={[
        { value: 'MACMA', label: 'MACMA' },
        { value: 'Easy Gifts', label: 'Easy Gifts' },
        { value: 'Spark', label: 'Spark' },
      ]} />}
      <Button variant="contained" type="submit" disabled={isSubmitting || Object.keys(errors).length}>Keresés</Button>
    </form>
  )}
  </Formik>;
}

function ArchiveTable({ data, showCeg }) {
  const { setSidebar } = useSidebar();
  let columns = [
    { width: 50, label: '', dataKey: 'statusenum', format: (col, val) => <StatusIcon d={val} /> },
    { width: 100, label: 'Dátum', dataKey: 'date', format: format.date },
    { width: 100, label: 'Típus', dataKey: 'type' },
    { width: 180, label: 'Partner', dataKey: 'sellto.name' },
    { width: 180, label: 'Ügyfél', dataKey: 'partner' },
    {
      width: 300, label: 'Tétel', dataKey: 'parcel', format: (col, val) => <>
        {val.parcel.length} tétel
        {val.cartons ? ` | ${val.cartons} karton` : ''}
        {val.palettes ? ` | ${val.palettes} paletta` : ''}
        {val.weight ? ` | ${val.weight} kg` : ''}
      </>
    },
    { width: 100, label: 'Kézbesítő', dataKey: 'asset' },
    { width: 180, label: 'Kézbesítve', dataKey: 'delivered', format: format.stamp },
  ];
  if (showCeg) {
    columns.splice(5, 0, { width: 80, label: 'Cég', dataKey: 'company' });
  }

  const onRowClick = useCallback(({ rowData }) => {
    setSidebar({ delivery: rowData.id });
  }, [setSidebar]);
  return <DataTable
    header={false}
    list={data}
    //  table="parcel"
    onRowClick={onRowClick}
    columns={columns}
  />
}