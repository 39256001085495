import React, { useState, useCallback, useMemo } from 'react';
import { Formik, FieldArray, useField } from 'formik';
import { Grid, Typography, Box, Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { postCommand, useRefresh } from '../../data/fetch';
import { COMMAND } from '../../../common';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../../components/input';
import DeleteIcon from '@mui/icons-material/Delete';
import AddressField from '../../parcel/AddressField';
import assets from '../../asset.json';
import { useAuth } from '../../data/auth';
import { dateError } from '../../order/validate';
import { Delete } from '@mui/icons-material';
import { backwards } from '../../data/utils';

const types = ['Beszerzés', 'Áttárolás', 'Kellék kiszállítás'];


function AssetField(props) {
  const opt = assets.filter(a => !a.kocsi && a.id !== 'Nincs beosztva').map(a => ({ value: a.id, label: a.id }));
  return <EnumField name="asset" label="Szállítás módja" options={opt} {...props} />
}

function emptyItem(i) {
  return !i || (!i.sku && !i.name);
}

function lastEmpty(list) {
  if (!list || !list.length) return true;
  return emptyItem(list[list.length - 1]);
}

export default function ParcelDialog(props) {
  let { rec, op } = props.open;
  let refresh = useRefresh();
  let { user } = useAuth();
  const submit = async (values) => {
    values.address = `${values.country || ''} ${values.zip || ''} ${values.city || ''}, ${values.street || ''}${values.street2 ? ' ' + values.street2 : ''}`;

    if (op === 'insert') {
      values.back = backwards(values);
      values.asset = 'SAJÁT';
      values.salesperson = {
        code: user.salesperson,
        name: user.nev,
        tel: user.tel,
        email: user.email
      };
      values.user = user;

      await postCommand('delivery', COMMAND.ADD_PARCEL, values);
    }
    if (op === 'update') {
      await postCommand('delivery', COMMAND.UPDATE_PARCEL, { id: rec.id, updates: values }); //ha át is ütemezte, akkor azt külön figyelnünk kell?
    }
    refresh();
    props.close();
  }

  const validate = useCallback((rec) => {
    console.log("validate", rec);
    let ret = {};
    if (!rec) rec = {};
    if (!rec.date) ret.date = 'A dátumot meg kell adni';
    if (!rec.partner) {
      ret.partner = 'Melyik partnerhez kapcsolódik?';
    }
    let err = dateError(rec.asset, rec.date);
    if (err) ret.date = err;
    console.log("validation error", ret);
    return ret;
  }, []);

  const del = async () => {
    await postCommand('delivery', COMMAND.DELETE_PARCEL, rec);
    refresh();
    props.close();
  }


  return <Formik initialValues={{ ...rec, type:'Beszerzés', deleted: false }} onSubmit={submit} validate={validate} {...props}>{({ handleSubmit, isSubmitting, values, errors }) => (
    <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }} autocomplete="off">
      <DialogTitle>{op === 'insert' ? 'Új beszerzés' : 'Beszerzés szerkesztése'}</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container columnSpacing={2}>
          <Grid item container columnSpacing={2} xs={12}>
            <Grid item>
              <ToggleField name="company" options={[
                { value: 'MACMA', label: 'MACMA' },
                { value: 'Easy Gifts', label: 'Easy Gifts' },
                { value: 'Spark', label: 'Spark' },
              ]} />
            </Grid>
            <Grid item xs={3}>
              <EnumField name="type" notnull label="Szállítási irány" options={types.map(t => ({ value: t, label: t }))} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField name="partner" inputProps={{ style: { fontWeight: 'bold' } }} label="Partner" />
            <TextField name="contact.name" label="Kontakt" />
            <TextField name="contact.tel" label="Telefon" />
            <TextField name="smemo" label="Megjegyzés" />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateField name="date" label="Szállítás napja" />
            <AddressField />
          </Grid>
          <Grid item xs={12} md={6} style={{ maxHeight: 380, overflowY: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Cikkszám</TableCell>
                  <TableCell>Megnevezés</TableCell>
                  <TableCell>Mennyiség</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <FieldArray name="lines" render={({ form, remove, push }) => {
                  let { lines } = values;
                  if (!lines) lines = [];
                  let it = lines.map((l, i) => <Item key={i} index={i} remove={remove} error={errors && errors.lines && errors.lines[i]} />);
                  if (!lines.length || !lastEmpty(lines)) {
                    it.push(<Item key={lines.length} index={lines.length} remove={null} />);
                  }
                  return it;
                }} />
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Box>
      {op === 'insert' ? <DialogActions>
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Mentés</Button>
      </DialogActions> : <DialogActions>
        <IconButton onClick={del}><DeleteIcon /></IconButton><div style={{ flex: '1 0 0' }} />
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Mentés</Button>
      </DialogActions>
      }
    </form >
  )}
  </Formik>
}

function Item({ index, remove, error }) {
  let r = <TableRow key="l" style={{ background: error ? '#fcc' : 'inherit' }}>
    <TableCell><TextField name={`lines.${index}.sku`} margin="none" /></TableCell>
    <TableCell><TextField name={`lines.${index}.name`} margin="none" /></TableCell>
    <TableCell><TextField name={`lines.${index}.quantity`} type="number" margin="none" style={{ width: 65 }} inputProps={{ style: { textAlign: 'right' } }} /></TableCell>
    <TableCell>{remove && <IconButton onClick={() => {
      console.log("delete", index);
      remove(index);
    }}><Delete /></IconButton>}
    </TableCell>
  </TableRow>
  if (error) {
    return [r, <TableRow key="error" style={{ background: '#fcc' }}>
      <TableCell colSpan={8} align="right">{error}</TableCell>
    </TableRow>]
  }

  return r;
}