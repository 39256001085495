import React, { useState, useCallback, useMemo } from 'react';
import { Formik, useField } from 'formik';
import { Grid, Typography, Box, Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { postCommand, useRefresh } from '../../data/fetch';
import { COMMAND } from '../../../common';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../../components/input';


export default function PrepareDialog(props) {
  let { rec } = props.open;
  let refresh = useRefresh();
  const submit = async (values) => {
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: rec.id, status: 'READY', values });
    refresh();
    props.close();
  }

  let { cartons, palettes, weight } = rec;

  return <Formik initialValues={{ cartons, palettes, weight }} onSubmit={submit}>{({ handleSubmit, isSubmitting, errors }) => (
    <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }} autocomplete="off">
      <DialogTitle>Csomagok összekészítve</DialogTitle>
      <Grid container sx={{ p: 2 }} columnSpacing={2}>
        <Grid item xs={12} md={3}><TextField name="cartons" type="number" label="Kartonok száma" /></Grid>
        <Grid item xs={12} md={3}><TextField name="palettes" type="number" label="Paletták száma" /></Grid>
        <Grid item xs={12} md={3}><EnumField name="palette_type" label="Paletta típus" options={[             
              { value: 'BLUE PALLET', label: 'Kék raklap' },
              { value: 'EUR PALLET', label: 'EUR raklap' },
              { value: 'EW PALLET', label: 'Egyutas raklap' },
              { value: 'HALF PALLET', label: 'Fél raklap' },
        ]}/></Grid>
        <Grid item xs={12} md={3}><TextField name="weight" label="Súly" InputProps={{ endAdornment: 'kg' }} /></Grid>
      </Grid>
      <DialogActions>
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Összekészítve</Button>
      </DialogActions>
    </form >
  )}
  </Formik>
}