import React, { useState, useCallback, useMemo } from 'react';
import { Formik, useField } from 'formik';
import { Grid, Typography, Box,Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../components/input';


export default function AddressField(props) {
  return <Grid container columnSpacing={2}>
    <Grid item xs={4}><TextField name="country" label="Ország"/></Grid>
    <Grid item xs={8}><TextField name="zip" label="Irsz"/></Grid>
    <Grid item xs={12}><TextField name="city" label="Város"/></Grid>
    <Grid item xs={12}><TextField name="street" label="Utca"/></Grid>
  </Grid>
}