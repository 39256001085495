import React, { useState, useCallback, useMemo } from 'react';
import { Formik, useField } from 'formik';
import { Grid, Typography, Box, Button, Slider, TextField as MUITextField, IconButton, DialogActions, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { postCommand, useRefresh } from '../../data/fetch';
import { COMMAND } from '../../../common';
import { TextField, ToggleField, Autocomplete, DateField, EnumField } from '../../components/input';
import DeleteIcon from '@mui/icons-material/Delete';
import AddressField from '../../parcel/AddressField';
import assets from '../../asset.json';
import { useAuth } from '../../data/auth';
import { dateError } from '../../order/validate';

const types = ['Kiszállítás', 'Minta darab', 'Alapanyag elhozatal', 'Visszáru', 'Beszerzés', 'Alvállalkozói kiszállítás', 'Alvállalkozótól vissza', 'Áttárolás', 'Kellék kiszállítás'];


function AssetField(props) {
  const opt = assets.filter(a => !a.kocsi && a.id !== 'Nincs beosztva').map(a => ({ value: a.id, label: a.id }));
  return <EnumField name="asset" label="Szállítás módja" options={opt} {...props} />
}

export default function ParcelDialog(props) {
  let { rec, op } = props.open;
  let refresh = useRefresh();
  let { user } = useAuth();
  const submit = async (values) => {
    values.address = `${values.country || ''} ${values.zip || ''} ${values.city || ''}, ${values.street || ''}${values.street2 ? ' ' + values.street2 : ''}`;
    values.salesperson = user.salesperson;
    values.user = user;

    if (op === 'insert') {
      await postCommand('delivery', COMMAND.ADD_PARCEL, values);
    }
    if (op === 'update') {
      await postCommand('delivery', COMMAND.UPDATE_PARCEL, { id: rec.id, updates: values }); //ha át is ütemezte, akkor azt külön figyelnünk kell?
    }
    refresh();
    props.close();
  }

  const validate = useCallback((rec) => {
    console.log("validate", rec);
    let ret = {};
    if (!rec) rec = {};
    if (!rec.date) ret.date = 'A dátumot meg kell adni';
    if (!rec.type) ret.type = 'Az irányt meg kell adni';

    if (!rec.partner) {
      ret.partner = 'Melyik partnerhez kapcsolódik?';
    }
    let err = dateError(rec.asset, rec.date);
    if(err) ret.date = err;
    console.log("validate", ret);
    return ret;
  }, []);

  const del = async () => {
    await postCommand('delivery', COMMAND.DELETE_PARCEL, rec);
    refresh();
    props.close();
  }


  return <Formik initialValues={{ ...rec, deleted: false }} onSubmit={submit} validate={validate} {...props}>{({ handleSubmit, isSubmitting, errors }) => (
    <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }} autocomplete="off">
      <DialogTitle>{op === 'insert' ? 'Új szállítás' : 'Szállítás szerkesztése'}</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} >
            <EnumField name="type" notnull label="Szállítási irány" options={types.map(t => ({ value: t, label: t }))} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField name="partner" inputProps={{ style: { fontWeight: 'bold' } }} label="Partner" />
            <DateField name="date" label="Szállítás napja" />
            <AssetField />
          </Grid>
          <Grid item xs={12} md={4}>
            <AddressField />
          </Grid>
          <Grid item xs={12} md={4} >
            <TextField name="item" label="Cikkszám" />
            <TextField name="name" label="Áru megnevezése" />
            <TextField name="quantity" label="Mennyiség" />
          </Grid>
        </Grid>
      </Box>
      {op === 'insert' ? <DialogActions>
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Mentés</Button>
      </DialogActions> : <DialogActions>
        <IconButton onClick={del}><DeleteIcon /></IconButton><div style={{ flex: '1 0 0' }} />
        <Button sx={{ color: "text.secondary" }} onClick={() => props.cancel()}>Mégsem</Button>
        <Button variant="contained" type="submit" disabled={Object.keys(errors).length !== 0 || isSubmitting}>Mentés</Button>
      </DialogActions>
      }
    </form >
  )}
  </Formik>
}