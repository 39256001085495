import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Button, Box, Paper, Grid, Typography, Link, Card, Menu, MenuItem, CardHeader, CardContent, Table, TableRow, TableCell, TableContainer, TableBody, ListItemIcon, ListItemText, Tooltip, Tabs, Tab } from "@mui/material";
import { useDialog } from '../components/Dialog'
import { Header } from '../components/Header';
import { Panel, useTab, PageTabs, PanelField } from '../components/Panel';
import { dateToMoment, format, now, nowdate } from '../../utils';
import DataDisplay from '../components/DataDisplay'
import { Delete, DoneAll, Drafts, Edit, LocalShipping, Map, MoreVert, Print, Refresh, ShoppingCart, AccessTime, AlternateEmail } from '@mui/icons-material';
import { postCommand, useJSON, useRefresh } from '../data/fetch';
import StatusIcon from '../parcel/StatusIcon';
import { COMMAND } from '../../common';
import { AtveteliPrint, printAtveteli } from './AtveteliPrint';
import config from "../config.json";
import assets from "../asset.json";
import EventList from '../eventlist/EventList';
import { RaktarPrint, printRaktar } from './RaktarPrint';
import { GLSDisplay, GLSFunctions, GLSLabelButton, TrackingButton } from './GLSDisplay';
import Loading from '../components/Loading';

let bgcolor = {
  "MACMA": "#EE6600",
  "Easy Gifts": "#6B508B",
  "Spark": "#ADD342"
}

function mailLink(d) {
  // https://outlook.live.com/owa/#subject=SUBJECT&body=BODY&to=TO@EXAMPLE.COM&path=%2fmail%2faction%2fcompose
  let to = (d.salesperson && d.salesperson.email) || '';
  let subject = ((d.partner || '') + ' ' + (d.date || ''));
  let ref = [];
  for (let i = 0; i < d.parcel.length; i++) {
    let p = d.parcel[i];
    ref.push(`${p.order} ${p.quantity} db  ${p.item} ${p.name}`);
  }
  let body = '';
  if (ref.length) {
    body = ref.join("%0D%0A");
  }
  let link = `mailto:${to}?subject=${subject}&body=${body}`;
  //  console.log(link);
  return link;
}

export default function DeliveryPanel({ id, sidebar }) {
  const [menu, setMenu] = useState({ show: false, anchor: null });
  const [tab, setTab] = useState(0);
  const [print, setPrint] = useState(false);


  const clearPrint = useCallback(() => {
    console.log("clearPrint");
    setTimeout(() => setPrint(false), 1000);
  }, [setPrint]);

  useEffect(clearPrint, [id]); //ha az ID változik, akkor mindenképpen töröljük

  useEffect(() => {
    window.addEventListener("message", clearPrint);
    return () => window.removeEventListener("message", clearPrint);
  }, [clearPrint]);


  const refresh = useRefresh();
  let { setDialog } = useDialog();
  const delivery = useJSON({ url: '/delivery/', id: sidebar.open.delivery, setNull:true });
  if (delivery === null) return <Loading />
  if (!delivery) return <Panel header={<Header color='#ffffff' bgcolor='#ccc' caption='' title='Ilyen kártya már nincs' subtitel='' icon='' sidebar={sidebar} />} content={<Box sx={{ bgcolor: 'background.default', p: 2, overflow: 'auto', minHeight: '100%' }}></Box>} />


  let d = delivery;
  //  console.log('delivery', delivery);
  let mailto = mailLink(d);
  d.palettatxt = d.palettes ? `${d.palettes} ${d.palette_type || 'plt'}` : '';
  let asset = assets.find(a => a.id === d.asset);
  let orders = delivery.parcel.reduce((acc, p) => {
    let id = p.rsz || p.order || p.type;
    if (p.rsz) {
      id = p.order + ' / ' + p.rsz + ' / ' + (p.raktar || '?');
    } else {
      id += ' / ' + (p.raktar || '?');
    }
    if (acc[id]) {
      acc[id].item.push(p);
    } else {
      acc[id] = { raktar: p.raktar, rsz: p.rsz, order: p.order, type: p.type, id, item: [p] }
    }
    return acc;
  }, {});

  const loaded = async () => {
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: id, status: 'LOADED' });
    refresh();
  }

  const notready = async () => {
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: id, status: 'NOTREADY' });
    refresh();
  }

  const kiszall = async () => {
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: id, status: 'DELIVERED' });
    refresh();
  }

  const collecting = async () => {
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: id, status: 'COLLECTING' });
    refresh();
  }

  const starting = async () => {
    await postCommand('delivery', COMMAND.CHANGE_STATUS, { delivery: id, status: 'STARTING' });
    refresh();
  }

  const toggleMenu = (ev) => {
    setMenu({ show: !menu.show, anchor: ev.currentTarget });
  }


  let op = [];
  op.push(<Tooltip key='mail' title='Levél az üzletkötőnek'><IconButton href={mailto}><AlternateEmail /></IconButton></Tooltip>);
  let printdlg = null;
  let printBtn = <IconButton key="print" disabled={!!print}><Print onClick={() => setPrint(now())} /></IconButton>;
  if (d.statusenum === 0) {
    op.push(<Tooltip key="ready" title="Összekészítésre vár"><IconButton onClick={collecting}><AccessTime /></IconButton></Tooltip>);
    op.push(<IconButton key="edit"><Edit onClick={() => setDialog({ id: 'delivery', rec: delivery })} /></IconButton>);
    //    op.push(<IconButton key="print"><Print onClick={() => printRaktar(d.id, now())} /></IconButton>);
    op.push(printBtn);
    if (print) printdlg = <RaktarPrint id={id} job={print} />;
  }

  if (d.statusenum === 10) {
    op.push(<Tooltip key="ready" title="Összekészítve"><IconButton onClick={() => setDialog({ id: 'prepare', rec: delivery })}><ShoppingCart /></IconButton></Tooltip>);
    op.push(<Tooltip key="notready" title="Összekészítés visszavonása"><IconButton onClick={starting}><Drafts /></IconButton></Tooltip>);
    op.push(<IconButton key="edit"><Edit onClick={() => setDialog({ id: 'delivery', rec: delivery })} /></IconButton>);
    //op.push(<IconButton key="print"><Print onClick={() => printRaktar(d.id, now())} /></IconButton>);
    op.push(printBtn);
    if (print) printdlg = <RaktarPrint id={id} job={print} />;
  }


  if (d.statusenum === 1) {
    op.push(<Tooltip key="notready" title="Összekészítés visszavonása"><IconButton onClick={notready}><AccessTime /></IconButton></Tooltip>);
    //op.push(<IconButton key="print"><Print onClick={() => printAtveteli(d.id, now())} /></IconButton>);
    op.push(printBtn);
    if (print) printdlg = <AtveteliPrint id={id} job={print} />;
    if (!asset.noshipment) {
      op.push(<Tooltip key="loaded" title="Fuvarozónál"><IconButton onClick={loaded}><LocalShipping /></IconButton></Tooltip>);
    }
    if (asset.id === "Személyes átvétel") {
      op.push(<Tooltip key="delivered" title="Vevőnek átadva"><IconButton onClick={kiszall}><DoneAll /></IconButton></Tooltip>);
    }
  }

  if (d.statusenum === 2) {
    op.push(<Tooltip key="notready" title="Visszavétel raktárba"><IconButton onClick={notready}><Drafts /></IconButton></Tooltip>);
    op.push(<Tooltip key="delivered" title="Kiszállítva"><IconButton onClick={kiszall}><DoneAll /></IconButton></Tooltip>);
  }

  return <Panel header={
    <Header color='#ffffff' bgcolor={bgcolor[d.company]}
      title={`${d.partner || ''}`}
      subtitle={<>{d.address} <Link component={IconButton} href={`https://google.com/maps/?q=${encodeURIComponent(d.address)}`} target="maps" ><Map /></Link></>}
      sidebar={sidebar}
      icon={<StatusIcon d={d} />}
      caption={d.status || 'Összekészítésre vár'}
      menu={<div>
        {op}
      </div>}
      tabs={
        <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
          <Tab label="Alapadatok" key="alap" />
          <Tab label="Történet" key="history" />
        </Tabs>
      }
    />
  }
    content={tab === 1 ? <EventList id={d.id} /> : <Box sx={{ bgcolor: 'background.default', p: 2, overflow: 'auto', minHeight: '100%' }}>
      <Grid container>
        <DataDisplay label="Fuvarozó" data={d.asset} xs={4} />
        <DataDisplay label="Nyomtatvány" data={d.print} xs={4} />
        <DataDisplay label="Szállítás" data={d.date} xs={4} format={format.date} />
        <DataDisplay label="Karton" data={d.cartons} xs={4} />
        <DataDisplay label="Paletta" data={d.palettatxt} xs={4} />
        <DataDisplay label="Súly" data={d.weight} xs={4} />
        <DataDisplay label="Szállítási irány" data={d.type} xs={4} />
        <DataDisplay label="Számlaszám" data={d.invoice || (d.nobill ? 'Számla nélkül kiküldhető' : false)} xs={4} />
        <DataDisplay label="Utánvét összege" data={d.cod} xs={4} format={format.money} />
        {(d.statusenum === 1 || d.statusenum === 2) ? <DataDisplay xs={12} label="Nyomkövetés" >
          <GLSFunctions d={d} />
        </DataDisplay> : null}
        <DataDisplay label="Sikertelen" data={d.failed} xs={4} format={format.fromNow} />
        <DataDisplay label="Ok" data={d.failreason} xs={4} />
        <DataDisplay label="Megjegyzés" data={d.failmemo} xs={4} />
        {/* <pre>{JSON.stringify(orders, 0, 2)}</pre> */}
      </Grid>
      {Object.values(orders).map(o => <Order key={o.id} o={o} setDialog={setDialog} />)}
      {printdlg}
    </Box>}
  />
}


function Order({ o, setDialog }) {
  const { sellto, memo, memo2, smemo, contact, salesperson, ext, statusenum, company, created } = o.item[0];
  //  console.log("Planner Order", o);
  let title = [];
  let c = { MACMA: 'Macma', 'Easy Gifts': 'Easy', Spark: 'setup' }[company];
  let { raktar, order, rsz, type } = o;
  if (order) {
    title.push(<a key="order" href={`${config.bc}?company=${c}&page=9305&filter='No.'%20IS%20'${order}'`} target="bc">{order}</a>)
    title.push(<span key="sep1"> / </span>);
  }
  if (rsz) {
    title.push(<a key="rsz" href={`${config.bc}?company=${c}&page=7339&filter='No.'%20IS%20'${rsz}'`} target="bc">{rsz}</a>);
    title.push(<span key="sep2"> / </span>);
  }
  if (raktar) {
    title.push(<span key="raktar">{raktar}</span>)
  }
  /*  
    if (type){
      title.push(<span key="type">{type}</span>)
    }
  */
  if (!title.length) title = type || "Raktárosi megbízás";

  let items = null;
  if (o.item) {
    items = o.item.sort((a, b) => a.salesline - b.salesline).map((i, idx) => <Item key={i.salesline || idx} i={i} setDialog={setDialog} />);
  }

  return <Card style={{ marginBottom: 8 }}>
    <div style={{ padding: 8 }}>
      <div style={{ display: 'flex' }}>
        <Typography variant="subtitle2" style={{ flexGrow: 1, padding: 4, textAlign: 'center' }}>{title}
        </Typography>
        <div>
          {!statusenum && <Edit style={{ fontSize: 16, color: '#999' }} onClick={
            () => {
              setDialog({ id: 'order', op: 'update', rec: { ids: o.item.map(i => i.id), defval: o.item[0] } });
            }
          } />}
        </div>
      </div>
      <Grid container>
        {sellto && <DataDisplay xs={6} label='Partner' data={`${sellto.name || ''} (${sellto.no || ''})`} />}
        {salesperson && <DataDisplay xs={6} label='Üzletkötő' data={`${salesperson.name || ''}`} />}
        {ext && <DataDisplay xs={6} label='Külső bizonylatszám' data={ext} />}
        {contact && <DataDisplay xs={6} label='Cím kontakt' data={`${contact.name || ''} (${contact.tel || 'tel: ?'})`} />}
        <DataDisplay xs={6} label='Létrehozva' data={created} />
        <DataDisplay xs={6} label='Sofőr megjegyzés' data={smemo} />
        <DataDisplay xs={6} label='Megjegyzés' data={memo} />
        <DataDisplay xs={6} label='Webshop Megjegyzés' data={memo2} />
      </Grid>
    </div>
    {items}
  </Card>
}

function Item({ i, setDialog }) {
  let lines = null;
  if (i.lines) {
    lines = i.lines.map((l, idx) => <ParcelLine key={idx} l={l} />);
    return lines;
  }

  return <Typography variant="body2" component="div" style={{ padding: 4, fontSize: 12, borderTop: '1px solid #ccc' }} key={i.salesline}>
    <div style={{ display: "flex" }}>
      <div style={{ maxWidth: 50, minWidth: 50, textAlign: 'right', paddingRight: 8, whiteSpace: 'nowrap', }}>{i.quantity} db</div>
      <div style={{ maxWidth: 25, minWidth: 25, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {i.printed && <Print style={{ color: "#ccc", fontSize: 14, maringTop: -2 }} />}
      </div>
      <div style={{ maxWidth: 90, minWidth: 90, textAlign: 'left', paddingRight: 8, whiteSpace: 'nowrap', }}> {i.item}</div>
      <div style={{ flexGrow: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{i.name}</div>
      {(!i.statusenum || i.statusenum === 10) && <div style={{ whiteSpace: 'nowrap', }}>
        <Edit style={{ fontSize: 16, color: '#999' }} onClick={() => { setDialog({ id: 'parcel', op: 'update', rec: i }); }} />
      </div>}
    </div>
    {lines}
    {i.linememo && <div style={{ textAlign: 'right' }}>{i.linememo}</div>}
  </Typography>
}

function ParcelLine({ l }) {
  return <Typography variant="body2" component="div" style={{ padding: 4, fontSize: 12, borderTop: '1px solid #ccc' }}>
    {l.quantity || '? '}db {l.sku} {l.name}
  </Typography>
}