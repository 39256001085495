import { Protected, useAuth } from "../data/auth";
import "./styles.css";
import Board from "react-trello";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import UzletkotoMenu from "./UzletkotoMenu";
import moment from "moment";
import { dateFromMoment, dateToMoment, nowdate } from "../../utils";
import { registerPanel, Sidebar, useSidebar } from '../components/Sidebar'
import DeliveryPanel from "./DeliveryPanel";
import { postCommand, useJSON, useRefresh } from "../data/fetch";
import { lightBlue, lightGreen, red, teal } from '@mui/material/colors';
import assets from '../asset.json';
import { SearchInput } from "../components/Search";
import { Logout, Menu, Add, Refresh, Label, LabelOff } from "@mui/icons-material";
import COMMANDS from '../../common/commands';
import { ConfirmProvider } from 'material-ui-confirm';
import { Button, Divider, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import StatusIcon from "../parcel/StatusIcon";
import { bgcolor } from "../driver/DeliveryPanel";
import OrderForm from "../order/OrderForm";
import OrderNr from "../order/OrderNr";
import { SalespersonArchive } from "../archive/ArchiveWindow";
import { Dialog, registerDialog } from "../components/Dialog";
import BillDialog from "./dlg/BillDialog";
import ParcelDialog from "./dlg/ParcelDialog";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import OrderDialog from "./dlg/OrderDialog";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { backwards } from "../data/utils";

const drawerWidth = 240;


function register() {
  registerPanel({ id: 'delivery', Component: DeliveryPanel })
  registerDialog({ id: 'bill', Component: BillDialog, size: 'sm' });
  registerDialog({ id: 'parcel', Component: ParcelDialog, size: 'md' });
  registerDialog({ id: 'order', Component: OrderDialog, size: 'md' });
}

const toCard = d => {
  let label = d.parcel.length + ' tétel';
  if (d.weight || d.cartons) {
    label = `${d.cartons || '0'} krt / ${d.weight || '0'} kg`
  }
  let backicon = (backwards(d)) ? <KeyboardDoubleArrowLeftIcon style={{ fontSize: 14 }} /> : '';
  let invicon = (d.invoice) ? <AttachFileIcon style={{ fontSize: 14 }} /> : '';
  let description = d.city && d.city.startsWith('Budapest') ? `${d.zip || ''} ${d.street || ''}` : `${d.country || ''} ${d.zip || ''} ${d.city || ''}`

  return {
    id: d.id, title: <div style={{ overflow: 'hidden' }}><StatusIcon d={d} style={{ fontSize: 14 }} /> {backicon} {invicon} {d.partner} </div>, label, description,
    draggable: false,
    style: { borderLeft: `5px solid ${bgcolor[d.company]}` }
  }
}

const assetsToTrello = (data, date) => {
  let lanes = assets.map(a => {
    return {
      id: a.id, style: { width: 280, background: a.color }, title: (a.id), cards: []
    }
  });
  if (!data) return { lanes };

  data.forEach(a => {
    if (a.date !== date) return;
    let l = lanes.find(l => l.id === a.asset || (l.id === 'Nincs beosztva' && !a.asset));
    if (!l) return;
    l.cards = l.cards.concat(a.delivery.map(toCard));
    l.label = l.cards.length
  })
  return { lanes };
}

const daysToTrello = (data, asset) => {
  let s = data.filter(a => a.asset === asset);
  console.log("daysToTrello", asset, s);
  const createLane = (date, delivery) => {
    let d = dateToMoment(date);
    let cidx = d.isoWeekday() * 100;
    console.log("cidx", cidx);
    let style = { width: 280, background: d.isBefore(moment(), 'd') ? red[400] : lightGreen[cidx] }
    return {
      id: date, title: d.format('MM.DD. dddd'), style, label: delivery.length,
      cards: delivery.map(toCard)
    }
  }

  let lanes = s.map(({ date, delivery }) => {
    return createLane(date, delivery);
  })

  for (let i = 0; i < 10; i++) {
    let n = moment().add(i, 'd');
    let date = dateFromMoment(n);
    if (n.isoWeekday() < 6 && !lanes.find(l => l.id === date)) {
      lanes.push(createLane(date, []));
    }
  }

  lanes.sort((a, b) => a.id.localeCompare(b.id))
  return { lanes };
}


const columnToDelivery = (data, asset, date) => {
  let c = data.find(a => a.asset === asset && a.date === date);
  return c ? c.delivery : [];
}

const cardIdToDelivery = (data, asset, date, id) => {
  return columnToDelivery(data, asset, date).find(d => d.id === id);
}


const matchingCards = (slots, term) => {
  if (!term) return slots;
  term = term.toLowerCase();
  return slots.map(s => {
    return { ...s, delivery: s.delivery.filter(d => JSON.stringify(d).toLocaleLowerCase().includes(term)) };
  })
}

const calcMenus = slots => {
  if (!slots) return { days: [], assets: [] };
  let days = {};
  let a = assets.map(a => {
    return { ...a, found: false }
  });
  slots.forEach(s => {
    if (s.delivery.length) {
      if (!days[s.date]) {
        days[s.date] = { label: dateToMoment(s.date).format('MM.DD dddd'), date: s.date };
      }
      a.find(a => a.id === s.asset).found = true;
    }
  });

  return { days: Object.values(days).sort((a, b) => a.date < b.date ? -1 : 1), assets: a.filter(a => a.found) }
}




const searchFilter = (term) => {
  term = term.toLowerCase();
  return d => JSON.stringify(d).toLocaleLowerCase().includes(term);
}

const statusFilter = (status) => {
  return d => status.includes(d.statusenum);
}

const trackingFilter = (tr) => {
  console.log("tracking filter", tr);
  if (tr === 2) return d => !d.trackingnr;
  return d => d.trackingnr;
}



const UzletkotoApp = () => {
  const { setSidebar } = useSidebar();
  const [state, setState] = useState({ date: { date: nowdate(), label: 'Ma' } });
  const db = useJSON({ url: '/delivery/salesperson/active' });
  const [data, setData] = useState([]);
  const [cards, setCards] = useState({ lanes: [] });
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState([]);
  const [tracking, setTracking] = useState(0);

  const [menu, setMenu] = useState({ days: [], assets: [] });
  const refresh = useRefresh();
  const { user, logout } = useAuth();

  useEffect(register, []);


  useEffect(() => {
    console.log("Search", search);
    let filters = [];
    if (search) {
      filters.push(searchFilter(search));
    }
    if (status.length) {
      filters.push(statusFilter(status));
    }
    if (tracking) {
      filters.push(trackingFilter(tracking));
    }

    if (!filters.length) {
      return setData(db);
    }


    let filter = s => {
      for (let i = 0; i < filters.length; i++) {
        if (!filters[i](s)) return false;
      }
      return true;
    }

    let d = db.map(s => {
      return { ...s, delivery: s.delivery.filter(filter) };
    })
    setData(d);

  }, [db, search])

  useEffect(() => {
    if (state.date) {
      console.log("Date data");
      setCards(assetsToTrello(data, state.date.date));
    } else if (state.asset) {
      setCards(daysToTrello(data, state.asset));
    }
    setMenu(calcMenus(data));
  }, [state, data, setCards, setMenu])

  const setDate = d => {
    console.log("setDate", d);
    setState({ date: d });
  }

  const setAsset = a => {
    console.log("setAsset", a);
    setState({ asset: a || 'Nincs' });
  }

  const eventHandlers = {
    onCardClick: (cardId, metadata, laneId) => {
      console.log("onCardClick", { cardId, metadata, laneId });
      let asset = laneId, date = state.date && state.date.date;
      if (!date) {
        asset = state.asset; date = laneId;
      }
      let delivery = cardIdToDelivery(data, asset, date, cardId);
      console.log("Delivery", delivery);
      if (delivery) {
        setSidebar({ delivery: delivery.id });
      }
    },

  }

  let username = `[${user.user}]`;

  let content = null;
  if (state.addorder) {
    const close = () => setState({ ...state, addorder: false, newOrder: null });
    content = state.newOrder ? <OrderForm order={state.newOrder} close={close} onSubmit={() => refresh()} /> : <OrderNr setOrder={o => setState({ ...state, newOrder: o })} close={close} />
  } else {
    content = <div style={{
      position: 'absolute',
      top: 0, left: 0, bottom: 0, right: 0,
      overflow: 'hidden',
    }}>

      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
          <Toolbar variant="dense">
            <IconButton onClick={() => refresh()} style={{ color: '#fff' }}><Refresh /></IconButton>
            <Typography variant="h6" noWrap component="div">
              {username}&nbsp;&nbsp;
            </Typography>
            <Typography variant="h6" noWrap component="div">
              szállítások
            </Typography>
            <Typography variant="h6" noWrap component="div" style={{ width: '20%', textAlign: 'center' }}>
              {state.archive ? 'Archívum' : state.date ? state.date.label : (state.asset)}
            </Typography>
            <StatusFilter setStatus={setStatus} value={status} />
            <TrackingFilter setTracking={setTracking} value={tracking} />

            <SearchInput setSearch={setSearch} value={search} />
            <Fab color="secondary" aria-label="add" style={{ position: 'fixed', right: 20, top: 20 }} onClick={() => setState({ ...state, addorder: true })} >
              <Add />
            </Fab>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            zIndex: (theme) => theme.zIndex.drawer - 2
          }}
        >
          <Toolbar variant="dense" />
          <UzletkotoMenu assets={menu.assets} days={menu.days} setDate={setDate} setAsset={setAsset} active={state} setArchive={() => setState({ archive: true })} />
          <Divider />
          <Button startIcon={<Logout />} onClick={logout}>Kijelentkezés</Button>
        </Drawer>
        {state.archive ? <SalespersonArchive term={search} /> :
          <Board data={cards} cardDraggable={false} laneDraggable={false} hideCardDeleteIcon style={{
            background: '#fff',
            flexGrow: 1,
            paddingTop: 50,
          }}
            {...eventHandlers}
          />}

      </Box>
      <Sidebar />
      <Dialog />
    </div>
  }

  return <Protected module="Üzletkötői felület" allowed={u => {
    return u && (u.admin || u.salesperson)
  }}>
    <ConfirmProvider
      defaultOptions={{
        confirmationText: 'OK',
        cancellationText: 'Mégsem',
        confirmationButtonProps: { autoFocus: true }
      }}
    >
      {content}
    </ConfirmProvider>
  </Protected >
}


const toggleStyle = (v, sel, s) => sel.includes(v) ? { color: '#fff', background: '#fff6', ...s } : { color: '#fff6', ...s };

function StatusFilter({ setStatus, value }) {
  return <ToggleButtonGroup value={value} onChange={(ev, v) => setStatus(v)} style={{ marginRight: 20 }}>
    <Tooltip title="Nincs előkészítve"><ToggleButton value={0} style={toggleStyle(0, value)} ><StatusIcon d={{ statusenum: 0 }} /></ToggleButton></Tooltip>
    <Tooltip title="Előkészítés alatt"><ToggleButton value={10} style={toggleStyle(10, value)}><StatusIcon d={{ statusenum: 10 }} /></ToggleButton></Tooltip>
    <Tooltip title="Előkészítve"><ToggleButton value={1} style={toggleStyle(1, value)} ><StatusIcon d={{ statusenum: 1 }} /></ToggleButton></Tooltip>
    <Tooltip title="Úton"><ToggleButton value={2} style={toggleStyle(2, value)}><StatusIcon d={{ statusenum: 2 }} /></ToggleButton></Tooltip>
    <Tooltip title="Kész"><ToggleButton value={3} style={toggleStyle(3, value)}><StatusIcon d={{ statusenum: 3 }} /></ToggleButton></Tooltip>
  </ToggleButtonGroup>;
}

function TrackingFilter({ setTracking, value }) {
  return <ToggleButtonGroup value={value} exclusive onChange={(ev, v) => setTracking(v)} style={{ marginRight: 20 }}>
    <Tooltip title="Van trackingnr"><ToggleButton value={1} style={toggleStyle(1, [value])} ><Label /></ToggleButton></Tooltip>
    <Tooltip title="Nincs még trackingnr"><ToggleButton value={2} style={toggleStyle(2, [value])}><LabelOff /></ToggleButton></Tooltip>
  </ToggleButtonGroup>;
}

export default UzletkotoApp;